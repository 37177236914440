.x {
    font-size: 1em !important;
}

.mistake-type-label {
    display: inline-block;
    /*background: none;*/
    font-size: 13px;
    font-family: 楷体, STKaiti, serif;
    border-width: 0;
    border-color: #0C0C0C;
    border-radius: 10%;
    user-select: none;
}

.table-title {
    font-size: xx-large;
    font-family: 楷体, STKaiti, serif;
    margin-top: 1rem;
}