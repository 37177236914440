/* PC端弹窗 */
.dialog-container {
  width: 620px;
  padding-top: 170px;
  font-family: 'Alibaba PuHuiTi 2.0';
}

.dialog-image {
  position: absolute;
  overflow: hidden;
  top: -0.5px;
  width: 620px;
  /* height: 169px; */
}

.dialog-box {
  margin: 0 48px;
}

.dialog-title {
  margin-top: 24px;
  margin-bottom: 24px;
  color: #1a1a1a;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.dialog-content p {
  margin-bottom: 8px;
  font-size: 16px;
  color: #2D2D2D;
  font-size: 16px;
  line-height: 24px;
}

.lingo-whale {
  color: #1549F2;
  font-weight: 600;
}

.dialog-btn,
.close-btn {
  width: 136px;
  border-radius: 4px;
  font-family: 'Alibaba PuHuiTi 2.0';
}

.dialog-btn {
  padding: 4px 15px;
  margin-top: 16px !important;
  color: #fff !important;
}

.close-btn {
  margin-top: 2px !important;
  margin-bottom: 36px !important;
  padding: 0;
  color: #B7B8B9 !important;
  font-size: 12px !important;
  line-height: 18px;
}

.close-btn:hover {
  color: #2D2D2D !important;
}

.close-btn:hover {
  background-color: #fff !important;
}

.to-new-website {
  display: block;
  padding: 0 38px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  line-height: 18px;
}

.home-side {
  position: fixed;
  right: 16px;
  top: 50%;
}

.experience-new-version,
.talk-new-version {
  padding: 4px 6px;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #FFF;
}

.experience-new-version .icon-new {
  display: block;
  width: 18px;
  margin: 0 auto;
  color: #FAAD14;
}

.experience-new-version p,
.talk-new-version p {
  margin-bottom: 0;
  color: #7A7B7B;
  text-align: center;
  font-size: 10px;
  font-family: 'Alibaba PuHuiTi 2.0';
  line-height: 16px;
}

.talk-new-version {
  margin-top: 8px;
}

.talk-new-version .icon-Message {
  display: block;
  width: 14px;
  margin: 0 auto;
  color: #2D2D2D;
}

.talk-about-new-version {
  position: absolute;
  right: 75px;
  bottom: -22px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #3C73F5;
  color: #fff;
  align-items: flex-start;
  gap: 8px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi 2.0;
  line-height: 22px;
}

.talk-about-new-version span {
  display: block;
  width: 168px;
}

.talk-about-new-version .close-talk {
  margin-top: 8px;
  float: right;
  background-color: #3C73F5;
  padding: 0 7px;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
}

.talk-about-new-version .icon-Arrow-Wrapper {
  position: absolute;
  right: -11px;
  bottom: 35px;
  color: #3C73F5;
}

.experience-new-version,
.talk-new-version {
  cursor: pointer;
}

.experience-new-version:hover,
.talk-new-version:hover {
  border: 1px solid #595959;
}

.new-container {
  width: 475px;
  padding: 0 32px;
}

.new-title {
  display: block;
  color: #1A1A1A;
  font-size: 16px;
  font-family: 'Alibaba PuHuiTi 2.0';
  font-weight: 600;
  line-height: 24px;
}

.new-feedback-list {
  padding: 24px 0 16px 0;
  margin-bottom: 0;
  list-style: none;
  cursor: pointer;
}

.new-feedback-item {
  display: inline-block;
  padding: 2px 8px;
  margin-right: 8px;
  margin-bottom: 8px;
  gap: 3px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  color: #2D2D2D;
  font-size: 14px;
  font-family: 'Alibaba PuHuiTi 2.0';
  line-height: 22px;
}

.new-feedback-item-active {
  border-color: #3C73F5;
  background-color: #3C73F5;
  color: #fff;
}

.new-feedback-text {
  display: block;
  width: 100%;
  min-height: 88px;
  max-height: 88px;
  padding: 5px 12px;
  margin-bottom: 16px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background-color: #FFF;
  font-size: 14px;
  font-family: 'Alibaba PuHuiTi 2.0';
  font-style: normal;
  line-height: 22px;
}

.new-feedback-text::-webkit-input-placeholder,
.new-feedback-contact::-webkit-input-placeholder
{
  color: #B7B8B9;
}

.new-feedback-contact {
  margin-bottom: 24px;
  height: 32px;
}

.new-feedback-text, .new-feedback-contact {
  display: block;
  width: 100%;
  padding: 5px 12px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  background-color: #FFF;
  font-size: 14px;
  font-family: 'Alibaba PuHuiTi 2.0';
  line-height: 22px;
}

.new-feedback-text:focus-visible, .new-feedback-contact:focus-visible {
  outline: none;
}

.new-btn {
  float: right;
  margin-bottom: 24px;
}

.talk-cancle,
.talk-submit {
  padding: 4px 15px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
}

.talk-submit {
  background-color: #2D2D2D;
  color: #fff;
}


.talk-submit:disabled {
  border: 1px solid #D9D9D9;
  background: #F5F5F5;
  color: #B7B8B9;
}

.talk-cancle {
  margin-right: 8px;
  background-color: #fff;
}

.feedback-success, .feedback-error {
  position: fixed;
  top: 40px;
  left: 50%;
  background-color: #fff;
  padding: 12px 24px;
  border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  transform: translateX(-137px);
  font-size: 14px;
  font-family: 'Alibaba PuHuiTi 2.0';
  line-height: 22px;
  color: #2d2d2d;
  z-index: 1070;
}

.feedback-success {
  width: 280px;
  transform: translateX(-137px);
  padding-left: 55px;
}

.feedback-error {
  width: 186px;
  padding-left: 50px;
  transform: translateX(-94px);
  z-index: 999;
}

.icon-CheckCircle {
  position: absolute;
  left: 22px;
  font-size: 24px;
  color: #52C41A;
}

.icon-error {
  position: absolute;
  left: 18px;
  font-size: 26px;
  color: #c41a1a;
}

.dialog-mobile-container {
  position: relative;
  width: 280px;
  background-color: #fff;
  padding-top: 100px;
  margin-bottom: 70px;
  border-radius: 4px;
  overflow: hidden;
}

.dialog-mobile-img {
  position: absolute;
  top: 0;
  left: -50%;
  transform: translateX(105px);
  height: 100px;
  /* overflow: hidden; */
}

.dialog-mobile-title {
  padding: 12px 0;
  margin-bottom: 0;
  color: #1A1A1A;
  text-align: center;
  font-family: 'Alibaba PuHuiTi 2.0';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.dialog-mobile-text {
  padding: 12px 20px;
  padding-top: 0;
}

.dialog-mobile-text p {
  margin-bottom: 0;
  color: #2D2D2D;
  text-align: justify;
  font-family: 'Alibaba PuHuiTi 2.0';
  font-size: 14px;
  line-height: 22px;
}

.dialog-mobile-know {
  width: 240px;
  margin: 16px 20px;
  margin-top: 12px;
  padding: 7px 12px;
  border-radius: 4px;
  border: 1px solid #3C73F5;
  background: #3C73F5;
  color: #fff;
  text-align: center;
  font-family: Helvetica Neue;
  font-size: 17px;
  line-height: 140%;
}

.dialog-mobile-know:hover {
  border: 1px solid #2754CF;
  background: #2754CF;
}

.dialog-mobile-close {
  width: 34px;
  height: 34px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  margin-top: 24px;
}

.dialog-mobile-close .icon-close {
  display: block;
  width: 22px;
  margin: 0 auto;
  font-size: 22px;
  color: #fff;
}

.mobile-dialog .MuiPaper-root {
  background: none;
  box-shadow: none;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
}

.mobile-body {
  position: relative;
  width: 320px;
  margin: 0 auto;
  z-index: 10;
}

.mobile-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 24px;
  font-family: Alibaba PuHuiTi 2.0;
  font-style: normal;
  background: url('https://deeplang-frontend.oss-cn-zhangjiakou.aliyuncs.com/mini-program/bg-imgs/lingowhale_promotion.png') no-repeat center;
  background-size: cover;
  position: relative;
}

.content-logo {
  position: absolute;
  height: 48px;
  left: 23px;
  top: 23px;
}
.mobile-title {
  margin-top: 44px;
  margin-bottom: 0;
  text-align: center;
  color: #3C59A7;
  font-family: Songti SC;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.mobile-slogan {
  width: 187px;
}

.mobile-content {
  margin-bottom: 12px;
  color: rgba(43, 43, 43, 0.66);
  font-size: 13px;
  font-weight: 400;
  line-height: 32px;
}

.text-dashed {
  width: 100%;
  display: inline-block;
  border-bottom: 1px dashed #7a7b7b;
}

.text-blue {
  color: rgba(60, 89, 167, 1);
}

.mobile-know {
  width: 100%;
  height: 40px;
  background-color: #fff;
  border: 1.5px solid #5B8BD5;
  border-radius: 44px;
  margin-top: 12px;
  box-sizing: border-box;
}

.mobile-know p {
  display: block;
  color: #3572CD;
  font-family: Helvetica Neue;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0;
}