.half-page-left {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding-right: 1em;
}

.half-page-right {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding-left: 1em;
}

.quote-wrapper {
    margin: 0.25em;
}

.quote-wrapper-annotating{
    margin: 0.25em;
}
.quote-wrapper-annotating:hover{
    margin: 0.25em;
    color: #3f51b5;
}

.rank {
    width: 2em;
    text-align: right;
    border: none;
    background: none;
    margin-right: 0.1em;
    display: inline-block;
    vertical-align: top;
    color: #8D8D8D;
    cursor: auto !important;
    user-select: none;
}

.quote {
    width: calc( 100% - 2.1em );
    display: inline-block;
    vertical-align: top !important;
    padding: 0;
}

.quote-text {
    font-size: large; /*large when in TriggersToolTips*/
    font-weight: bold;
    line-height: 1.5em;
    margin-bottom: 0;
    font-family: 楷体, STKaiti, serif;
}

.logo-box {
    display: inline-block;
    width: 22px;
}

.logo-box-mobile {
    display: inline-block;
    width: 24px;
}

.logo {
    display: inline-block;
    vertical-align: center;
    margin-left: 3px;
    margin-right: 3px;
    width: 14px;
    height: 14px;
    cursor: pointer;
}

.logo-mobile{
    display: inline-block;
    vertical-align: center;
    margin-left: 5px;
    margin-right: 5px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.def{
    font-family: 楷体, STKaiti, serif;
    margin-bottom: 0;
    font-size: 1.1rem;
    color: darkgreen;

    /*background: #FFF;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border: 2px solid #afafaf;
    z-index: 1000;*/
}

.type-label {
    display: inline-block;
    /*background: none;*/
    font-size: 14px;
    font-family: 楷体, STKaiti, serif;
    color: #545454;
    border-width: 0;
    border-color: #0C0C0C;
    border-radius: 10%;
    user-select: none;
}

.quote-tips-wrapper {
    height: 24px;

}

.mistake-dialog{
    min-width: 18em;
}

.zoomed {
    animation: zoom 0.5s 1;
}

.wobbled {
    animation: wobble 0.5s 1;
}

@keyframes zoom {
    50% {
        transform: scale(1.25);
    }
}

@keyframes wobble {
    25% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(-30deg);
    }
    75% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

/* mobile compatibility */
@media (hover: hover) and (pointer: fine) {
    .logo:hover {
        margin-top: 0;
        width: 20px;
        height: 20px;
    }
}

.copy-buffer {
    width: 1px;
    height: 0;
    border: 0;
    visibility: hidden;
    position: absolute;
}

.quote-author {
    text-align: right;
    display: inline-block;
    float: right;
}

.linkable {
    font-family: 楷体, STKaiti, serif;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
}

.linkable:hover {
    /*color: #01AAED;*/
    text-decoration: underline;
}

.not-linkable {
    font-family: 楷体,STKaiti, serif;
    /*cursor: pointer;*/
    cursor: default;
    display: inline-block;
    vertical-align: top;
}


.dash {
    width: 2em;
    display: inline-block;
    vertical-align: top;
    margin-right: 0.2em;
    margin-top: 0.65em; /* important 0.5 */
}

.checkbox {
    display: inline-block;
    vertical-align: top;
}

.kai-xx {
    font-family: 楷体,STKaiti,serif !important;
    font-size: 1.4em !important;
    line-height: 1em !important;
}

.MuiPagination-root{
    visibility: hidden !important;
}