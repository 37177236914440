.site-logo {
    width: 200px;
}

.site-logo-mobile {
    width: 150px;
}

.use-guide {
    font-family: 楷体, STKaiti, serif !important;
    font-size: large;
    /*border: 2px solid rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    margin-left: 2em;
    margin-right: 2em;
    padding: 1em;*/
}

.type-description {
    padding-left: 1.6em !important;
    margin-left: 1.6em !important;
}

.use-guide-title {
    font-family: 'Times New Roman', 楷体, STKaiti, serif !important;
    font-size: 1.2em !important;
}

.use-guide-detail {
}

.proverb-question-mark {
    font-size: 0.8em !important;
    padding: 0 !important;
    margin: 0 !important;
    margin-bottom: 0.6em !important;
    color: darkorange;
}

.proverb-answer {
    font-size: 1.2em !important;
}

.letter-sign {
    text-align: right;
}

.login-title {
    font-size: x-large;
}

.please-login {
    text-align: center;
    font-family: 楷体, STKaiti, serif !important;
    font-size: 1.2em;
    color: #1976d2;
    font-weight: bold;
    cursor: pointer;
}

.title {
    font-family: STZhongsong, serif;
    font-size: 43px;
    text-align: center;
    font-weight: 600;
    color: #000000b0;
    margin-top: 100px;
    margin-bottom: 80px;
}

.search-input {
    height: 54px;
    /*font-size: 1.3em;*/
}

.notification-title {
    text-align: center;
    font-size: 1.3em;
    font-family: 楷体, STKaiti, serif !important;
}

.notification-content {
    font-size: 1em;
    /*font-size: 1.2em;
    font-family: 'Times New Roman',楷体, STKaiti, serif !important;*/

}

.wechat-group {
    width: 250px !important;
}

.faq {
    margin: 0 !important;
    padding: 0 !important;
}

.mobile-guide {
    text-align: center;
    font-family: 楷体, STKaiti, serif !important;
    font-size: large;
    color: #1976d2;
}

.padding {
    height: 0.3em;
}

.ul {
    margin: 0;
    padding: 0;
    padding-left: 1em;
}

.mobile-hint {
    font-family: 楷体, STKaiti, serif !important;
    font-size: large;
}

.type-select-label {
    font-size: large;
    font-family: 楷体, STKaiti, serif !important;
}

.option-title {
    font-weight: bold;
    font-size: large;
    font-family: 楷体, STKaiti, serif !important;
    color: rgba(0, 0, 0, 0.6);
}

.option-item {
    font-size: large;
    font-family: 楷体, STKaiti, serif !important;
    line-height: 0.42em !important;
    color: rgba(0, 0, 0, 0.6);
}

.tree-mark {
    font-size: 1.3em;
    font-family: 楷体, STKaiti, serif !important;
    line-height: 0.42em;
    color: rgba(0, 0, 0, 0.6);

}

.option-title-menu {
    height: 2em;
}

.option-item-menu {
}

.translate-all-button {
    color: #505050 !important;
}

.translate-all-button:hover {
    color: deepskyblue !important;
}

.annotation-button:hover {
    color: deepskyblue !important;
}

.annotation-tip {
    text-align: center;
    color: #8D8D8D;
    font-weight: bold !important;
    font-size: 1.4em !important;
    font-family: 楷体, STKaiti, serif !important;
}

.annotation-desc {
    text-align: center;
    margin-top: 1rem;
}

.type-selection-tip {
    text-align: center;
    color: #8D8D8D;
    font-weight: bold !important;
    font-size: 1.4em !important;
    font-family: 楷体, STKaiti, serif !important;
}

.kai-xx {
    font-family: 楷体, STKaiti, serif !important;
    font-size: 1.4em !important;
    line-height: 1em !important;
}

.kai-xmx {
    font-family: 楷体, STKaiti, serif !important;
    font-size: 1.3em !important;
    line-height: 1em !important;
}

.kai-x {
    font-family: 楷体, STKaiti, serif !important;
    font-size: 1.2em !important;
}

.kai {
    font-family: 楷体, STKaiti, serif !important;
    font-size: 1em !important;
}

.feedback {
    font-family: 楷体, STKaiti, serif !important;
    font-size: 1.2em !important;
    cursor: pointer;
}

.no-sim-warning {
    font-family: 楷体, STKaiti, serif !important;
    font-size: 1em !important;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
}

.qrcode-warning {
    font-family: 楷体, STKaiti, serif !important;
    font-size: 1.1em !important;
    color: #1976d2;
}

/* override*/

.MuiList-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiBadge-anchorOriginTopRightCircular {
    /*min-width: 8px !important;
    height: 8px !important;
    border-radius: 4px !important;*/
    min-width: 16px !important;
    height: 16px !important;
    border-radius: 8px !important;

    padding: 3px !important;
}

.MuiMenuItem-root {
    min-height: 1.3em !important;
    padding-top: 1px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    padding-bottom: 1px !important;
}

.MuiAccordionSummary-root {
    padding-top: 0.5em !important;
    min-height: 2em !important;
}

.MuiAccordionSummary-content {
    padding: 0 !important;
    margin: 0 !important;
}

.sensitive-alert-report {
    cursor: pointer;

}
